.loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(255, 255, 255, 0.834) url("../../assets/images/loader.gif") center no-repeat;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0; /* Center vertically */
    left: 0; /* Center horizontally */
}
